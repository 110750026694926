'use strict'

// p2p multi-address code
exports.CODE_P2P = 421
exports.CODE_CIRCUIT = 290

exports.CODE_TCP = 6
exports.CODE_WS = 477
exports.CODE_WSS = 478

// Time to wait for a connection to close gracefully before destroying it manually
exports.CLOSE_TIMEOUT = 2000
