'use strict'

const { Buffer } = require('buffer')

exports.utf8 = exports['utf-8'] = {
  encode: function (data) {
    return isBinary(data) ? data : String(data)
  },
  decode: identity,
  buffer: false,
  type: 'utf8'
}

exports.json = {
  encode: JSON.stringify,
  decode: JSON.parse,
  buffer: false,
  type: 'json'
}

exports.binary = {
  encode: function (data) {
    return isBinary(data) ? data : Buffer.from(data)
  },
  decode: identity,
  buffer: true,
  type: 'binary'
}

exports.none = {
  encode: identity,
  decode: identity,
  buffer: false,
  type: 'id'
}

exports.id = exports.none

const bufferEncodings = [
  'hex',
  'ascii',
  'base64',
  'ucs2',
  'ucs-2',
  'utf16le',
  'utf-16le'
]

for (const type of bufferEncodings) {
  exports[type] = {
    encode: function (data) {
      return isBinary(data) ? data : Buffer.from(data, type)
    },
    decode: function (buffer) {
      return buffer.toString(type)
    },
    buffer: true,
    type: type
  }
}

function identity (value) {
  return value
}

function isBinary (data) {
  return data === undefined || data === null || Buffer.isBuffer(data)
}
